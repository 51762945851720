import { calculateTicketDate } from "components/Utility/calendarDates";

export function defaultDataReducer(state, action) {
    switch (action.type) {
      case "DATA_FETCH_INIT":
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case "DATA_FETCH_SUCCESS":
        return {
          ...state,
          isLoading: false,
          isError: false,
          data: action.payload,
        };
      case "DATA_FETCH_FAILUE":
        return {
          ...state,
          isLoading: false,
          isError: true,
          data: [],
        };
      default:
        return {
          ...state,
          isLoading: false,
          isError: true,
          data: [],
        };
    }
  }

  export function defaultTicketDateReducer(state, action) {
    switch (action.type) {
      case "DATA_FETCH_INIT":
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case "DATA_FETCH_SUCCESS":
        const ticketDate = action.payload[0].max_date_tickets
        return {
          ...state,
          isLoading: false,
          isError: false,
          data: ticketDate,
        };
      case "DATA_FETCH_FAILUE":
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      default:
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
    }
  }

  const tableHeaderStyle = {
    whiteSpace: "pre-wrap",
    verticalAlign: "text-top",
    backgroundColor:  "white",//"#007bff",
    color: "black",
    textAlign: "center",
    fontSize: "0.8rem",
  };
  
  const cellStyle = {
    backgroundColor: "white",
    color: "#172b4d",
    textAlign: "center",
  };

  export function defaultKPIDataReducer(state, action) {
    switch (action.type) {
      case "DATA_FETCH_INIT":
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case "DATA_FETCH_SUCCESS":
        var columnArray = Object.keys(action.payload.data[0]).map((item) => ({dataField: item, text: item, sort: true,  headerStyle: tableHeaderStyle,
          style: cellStyle}))
        var meterIDColumns = []
        var meterTagColumns = []
        var rest = []
        var dtColumns = []
        for (var i=0; i< columnArray.length; i++) {
          if(columnArray[i].dataField == 'meter_id') {
            meterIDColumns = [columnArray[i]]
          } else if (columnArray[i].dataField == 'meter_tag') {
            meterTagColumns = [columnArray[i]]
          } else if (columnArray[i].dataField == 'Absolute DT Generation' || columnArray[i].dataField == "dt_loss" || columnArray[i].dataField == "dt_pr") {
            continue
          } 
          else rest = [...rest, ...[columnArray[i]]]
        }
        const result = {...action.payload, ...{columnArray: [...meterIDColumns, ...meterTagColumns, ...rest]}}
        return {
          ...state,
          isLoading: false,
          isError: false,
          data: result,
        };
      case "DATA_FETCH_FAILUE":
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      default:
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
    }
  }

  export function defaultMeterRulesDataReducer(state, action) {
    switch (action.type) {
      case "DATA_FETCH_INIT":
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case "DATA_FETCH_SUCCESS":
        const meterTable = action.payload.rules.map((item) => (
          {rule_id: item.rule_id, plant_id: item.plant_id, meter_type: item.meter_type, reading_type: item.reading_type, rule_type: item.rule_type, rule_value: JSON.stringify(item.rule_value) }
        ))
        return {
          ...state,
          isLoading: false,
          isError: false,
          data: meterTable,
        };
      case "DATA_FETCH_FAILUE":
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      default:
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
    }
  }